import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import analyticsEN from '../locales/en/analytics.json';
import authEN from '../locales/en/auth.json';
import chatEN from '../locales/en/chat.json';
import dashboardEN from '../locales/en/dashboard.json';
import inTransitProductEN from '../locales/en/inTransitProduct.json';
import invoicesEN from '../locales/en/invoices.json';
import nssImportEN from '../locales/en/nssImport.json';
import partnersEN from '../locales/en/partners.json';
import placesEN from '../locales/en/places.json';
import productsEN from '../locales/en/products.json';
import quoteEN from '../locales/en/quote.json';
import settingsEN from '../locales/en/settings.json';
import shipmentEN from '../locales/en/shipment.json';
import simulationEN from '../locales/en/simulation.json';
import translationEN from '../locales/en/translation.json';
import analyticsJA from '../locales/ja/analytics.json';
import authJA from '../locales/ja/auth.json';
import chatJA from '../locales/ja/chat.json';
import dashboardJA from '../locales/ja/dashboard.json';
import inTransitProductJA from '../locales/ja/inTransitProduct.json';
import invoicesJA from '../locales/ja/invoices.json';
import nssImportJA from '../locales/ja/nssImport.json';
import partnersJA from '../locales/ja/partners.json';
import placesJA from '../locales/ja/places.json';
import productsJA from '../locales/ja/products.json';
import quoteJA from '../locales/ja/quote.json';
import settingsJA from '../locales/ja/settings.json';
import shipmentJA from '../locales/ja/shipment.json';
import simulationJA from '../locales/ja/simulation.json';
import translationJA from '../locales/ja/translation.json';

export const resources = {
  en: {
    auth: authEN,
    analytics: analyticsEN,
    chat: chatEN,
    dashboard: dashboardEN,
    invoices: invoicesEN,
    partners: partnersEN,
    places: placesEN,
    products: productsEN,
    quote: quoteEN,
    settings: settingsEN,
    shipment: shipmentEN,
    simulation: simulationEN,
    translation: translationEN,
    nssImport: nssImportEN,
    inTransitProduct: inTransitProductEN,
  },
  ja: {
    auth: authJA,
    analytics: analyticsJA,
    chat: chatJA,
    dashboard: dashboardJA,
    invoices: invoicesJA,
    partners: partnersJA,
    places: placesJA,
    products: productsJA,
    quote: quoteJA,
    settings: settingsJA,
    shipment: shipmentJA,
    simulation: simulationJA,
    translation: translationJA,
    nssImport: nssImportJA,
    inTransitProduct: inTransitProductJA,
  },
};

const options = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator'],

  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    fallbackLng: 'ja',
    returnEmptyString: false,
    parseMissingKeyHandler: (key) => `Translation for ${key} not found`,
    keySeparator: '.', // char to separate keys
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
